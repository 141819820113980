.wp-block-page-list.is-style-tabs {
  --link-color: var(--color-maroon-dark);
  --hover-color: var(--color-maroon-primary);
  --active-color: var(--color-maroon-primary);
  --border-color: transparent;

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background: rgba(0 0 0 / 0%);
  }

  @apply flex items-center justify-start gap-xs border-b border-grey-lighter pr-[200px];

  @media (max-width: 1023px) {
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    overflow: scroll;
    mask-image: linear-gradient(to right, rgba(0 0 0 / 100%) 80%, rgba(0 0 0 / 0%));
  }

  .wp-block-pages-list__item {
    @apply grid;
    scroll-snap-align: start;

    .wp-block-pages-list__item__link {
      @apply my-1 block text-nowrap px-2xs py-3 font-bold no-underline transition-colors duration-150;
      color: var(--link-color);

      &:hover,
      &:focus {
        color: var(--hover-color);
      }
    }

    &::after {
      content: '';
      @apply rounded border-b-2;
      border-bottom-color: var(--border-color);
    }

    &.current-menu-item {
      --link-color: var(--active-color);
      --border-color: var(--active-color);
    }
  }
}
