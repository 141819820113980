.wp-block-post-template {
  &.insights-post-template {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    place-items: start;

    .wp-block-post {
      gap: inherit;

      .wp-block-post-terms {
        font-size: utopia.clamp(14, 16, 400, 800);
      }

      .wp-block-post-title {
        font-size: utopia.clamp(20, 24, 400, theme(screens.2xl));
      }

      .wp-block-post-date {
        font-size: var(--font-size-sm);
      }

      .wp-block-post-featured-image {
        max-width: initial;

        .wp-post-image {
          border-radius: var(--space-5-10) var(--space-25-50);
        }
      }

      .wp-block-post-excerpt {
        font-size: 16px;
      }
    }

    .wp-block-post:nth-child(1) {
      .wp-block-post-title {
        font-size: utopia.clamp(20, 40, 400, 800);
      }

      .wp-post-image {
        border-radius: var(--space-5-10) var(--space-40-80);
      }

      .wp-block-post-excerpt {
        font-size: var(--font-size-base);
      }
    }

    .wp-block-post:not(:nth-child(1)) {
      gap: var(--space-10-20);

      .wp-block-post-excerpt {
        display: none;
      }
    }
  }

  &.insights-post-template.is-style-1 {
    .wp-block-post {
      display: grid;
      grid-template-columns: subgrid;

      .card-insight-post {
        display: grid;
      }
    }

    .wp-block-post:nth-child(1) {
      grid-template-columns: subgrid;
      grid-column: 1 / -1;
      grid-row: 1 / 1;

      .card-insight-post {
        grid-column: 1 / -1;
      }

      @media screen(md) {
        grid-column: 1 / -1;
        grid-row: 1 / 3;
      }

      @media (min-width: 1140px) {
        grid-column: 1 / -2;
        grid-row: 1 / 3;
      }
    }
  }

  &.insights-post-template.is-style-2 {
    .wp-block-post {
      display: grid;
      grid-template-columns: subgrid;

      .card-insight-post {
        display: grid;

        gap: inherit;
        grid-template-columns: subgrid;
      }
    }

    .wp-block-post:nth-child(1) {
      grid-template-columns: repeat(1, 1fr);
      grid-column: 1 / -1;

      @media (min-width: 1140px) {
        grid-template-columns: repeat(2, 1fr);

        .card-insight-post {
          grid-column: 1 / -1;
        }
      }
    }
  }
}
