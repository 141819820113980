@import 'tailwindcss/base';
@import './base/container.css';
@import './base/typography.css';

@import 'tailwindcss/components';
@import './components/menus.css';
@import './components/buttons.css';

@import 'tailwindcss/utilities';
@import './utilities/border.css';
@import './utilities/spacing.css';

@import './blocks/group.css';
@import './blocks/image.css';
@import './blocks/heading.css';
@import './blocks/page-list.css';
@import './blocks/pullquote.css';
@import './blocks/icon.css';

@import './blocks/variations/post-template.css';

:root {
  /* Colors */
  --color-navy-transparent: rgba(35 73 133 / 20%);
  --color-navy-transparent-80: rgba(35 73 133 / 80%);
  --color-navy-lightest: rgba(221 238 252 / 100%);
  --color-navy-lighter: rgba(38 104 207 / 100%);
  --color-navy-primary: rgba(35 73 133 / 100%);
  --color-navy-darkest: rgba(30 53 94 / 100%);
  --color-maroon-transparent-80: rgba(113 30 70 / 80%);
  --color-maroon-light: rgba(177 37 106 / 100%);
  --color-maroon-primary: rgba(146 34 88 / 100%);
  --color-maroon-dark: rgba(113 30 70 / 100%);
  --color-gold-transparent-80: rgba(133 117 80 / 80%);
  --color-gold-primary: rgba(133 117 80 / 100%);
  --color-green-primary: rgba(74 130 128 / 100%);
  --color-grey-lightest: rgba(241 241 241 / 100%);
  --color-grey-lighter: rgb(175 175 175 / 100%);
  --color-grey-primary: rgba(67 86 91 / 100%);

  /* Gradients */
  --gradient-light-blue: linear-gradient(to bottom, var(--color-navy-lightest) 10%, rgba(255 255 255 / 100%) 80%);
  --gradient-dark-blue: linear-gradient(164deg, var(--color-navy-darkest) 50%, #325087 90%);
  --gradient-dark-maroon: linear-gradient(160deg, var(--color-maroon-dark) 0%, #2c081a 80%);

  /* Fonts */
  --font-sans: 'Roboto', sans;
  --font-serif: 'Lora', serif;

  /* Font Sizes */
  --font-size-xs: utopia.clamp(12, 14);
  --font-size-sm: utopia.clamp(14, 16);
  --font-size-base: utopia.clamp(16, 20);
  --font-size-lg: utopia.clamp(20, 24);
  --font-size-xl: utopia.clamp(24, 30);
  --font-size-2xl: utopia.clamp(28, 40);
  --font-size-3xl: utopia.clamp(32, 60);

  /* Logo */
  --header-logo: utopia.clamp(290, 400);

  /* Spacing */
  @utopia clamps({
    pairs: [
      [3, 6],
      [5, 10],
      [10, 20],
      [15, 30],
      [20, 40],
      [25, 50],
      [30, 60],
      [35, 70],
      [40, 80],
      [50, 100],
      [60, 120],
      [70, 140],
      [80, 160],
      [100, 200]
    ],
    usePx: false,          /* Optional */
    prefix: 'space',       /* Optional */
    relativeTo: 'viewport' /* Optional */
  });

  /* Global Padding */
  --global-padding-inline: utopia.clamp(25, 80);

  /* Aspect Ratios */
  --aspect-4-3: 4 / 3;
  --aspect-3-4: 3 / 4;
  --aspect-5-4: 5 / 4;
  --aspect-4-5: 4 / 5;

  /* Utilitites for pull elements outside of the max width container */
}
