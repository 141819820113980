html {
  @apply font-serif antialiased;
}

/* Headings */
h1 {
  @apply text-3xl font-bold leading-none;
}

h2 {
  @apply text-2xl font-normal;
}

h3 {
  @apply text-xl font-normal;
}

h4 {
  @apply text-lg font-normal;
}

h5 {
  @apply text-base font-normal;
}

h6 {
  @apply text-sm font-normal;
}

ol.wp-block-list,
ol.wp-block-footnotes {
  @apply antialiased;
  @apply list-decimal;
}

ol.foo {
  @apply antialiased;
  @apply list-decimal;
}

ul.wp-block-list {
  @apply antialiased;
  @apply list-disc;

  ul {
    @apply list-circle;
  }
}
