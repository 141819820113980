.wp-block-heading {
  &.has-text-align-center {
    text-align: center;
  }

  &.has-text-align-right {
    text-align: right;
  }

  &.has-text-align-left {
    text-align: left;
  }
}
