.wp-block-outermost-icon-block {
  --background: transparent;
  --fill-color: inherit;
  --border-color: inherit;
  --border-width: 2px;
  --border-radius: 0;

  .icon-container {
    padding: var(--wp--preset--spacing--2);
    border-radius: var(--border-radius);
    outline: var(--border-width) solid var(--border-color);
    border-color: var(--border-color);
    background-color: var(--background);

    svg {
      fill: var(--fill-color);
    }
  }

  &.is-style-rounded-solid,
  &.is-style-rounded-outline {
    .icon-container {
      --border-color: inherit;
      --border-radius: 100%;
    }
  }

  &.is-style-rounded-solid .icon-container {
    --fill-color: #fff;
  }

  &.is-style-rounded-solid .icon-container:not(.has-icon-background-color) {
    --border-color: var(--color-maroon-primary);
    --background: var(--color-maroon-primary);
  }

  &.is-style-rounded-outline .icon-container {
    --border-color: var(--color-maroon-primary);
    --fill-color: var(--color-maroon-primary);
  }

  &.is-style-rounded-outline .icon-container.has-icon-color,
  &.is-style-rounded-outline .icon-container.has-icon-background-color {
    background-color: transparent !important;
    outline-color: inherit !important;
  }
}

/* Section Styles */
/* .is-style-section-maroon,
.is-style-section-navy,
.is-style-section-light-blue {
} */

/* Light Blue Section Style */
.is-style-section-light-blue .wp-block-outermost-icon-block {
  &.is-style-rounded-solid .icon-container:not(.has-icon-background-color) {
    --border-color: var(--color-navy-primary);
    --background: var(--color-navy-primary);
  }

  &.is-style-rounded-outline .icon-container {
    --border-color: var(--color-navy-primary);
    --fill-color: var(--color-navy-primary);
  }
}

/* Maroon Section Style */
.is-style-section-maroon .wp-block-outermost-icon-block {
  &.is-style-rounded-solid .icon-container:not(.has-icon-background-color) {
    --border-color: #fff;
    --background: #fff;
    --fill-color: var(--color-maroon-primary);
  }

  &.is-style-rounded-outline .icon-container {
    --border-color: #fff;
    --fill-color: #fff;
  }
}

/* Navy Section Style */
.is-style-section-navy .wp-block-outermost-icon-block {
  &.is-style-rounded-solid .icon-container:not(.has-icon-background-color) {
    --border-color: #fff;
    --background: #fff;
    --fill-color: var(--color-navy-primary);
  }

  &.is-style-rounded-outline .icon-container {
    --border-color: #fff;
    --fill-color: #fff;
  }
}
