.wp-block-pullquote {
  min-width: 100%;
  display: grid;
  place-content: center;

  blockquote {
    max-width: var(--wp--style--global--content-size);
  }

  cite {
    text-transform: uppercase;

    &::before {
      content: '— ';
    }
  }
}
