.wp-block-image {
  --max-width: min(100%, 500px);
  @apply mx-auto;

  max-width: var(--max-width);

  @media screen(lg) {
    --max-width: initial;
  }

  &.is-style-wing-mask {
    margin-right: -200px;
    mask-image: url('@images/griffin-wings.svg');
    mask-size: auto 100%;
    mask-repeat: no-repeat;

    img {
      mask-image: linear-gradient(to right, rgba(0 0 0 / 100%) 65%, rgba(0 0 0 / 0%) 92%);
    }
  }
}
