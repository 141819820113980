.wp-block-button {
  --border-color: var(--color-maroon-primary);
  --background: var(--color-maroon-primary);
  --color: #fff;
  --hover-border-color: var(--color-maroon-dark);
  --hover-background: var(--color-maroon-dark);
  --hover-color: #fff;
  --outline-color: var(--color-maroon-primary);

  .wp-block-button__link {
    @apply my-1 font-bold;
    transition:
      background-color 0.3s,
      color 0.3s,
      border-color 0.3s,
      outline 0.15s;

    &:focus {
      outline: 1px solid var(--outline-color);
      outline-offset: 2px;
    }
  }

  .wp-block-button__link:not(.has-link-color) {
    background-color: var(--background);
    color: var(--color);
    border-color: var(--border-color);

    &:hover,
    &:focus {
      background-color: var(--hover-background);
      color: var(--hover-color);
      border-color: var(--hover-border-color);
    }
  }

  /* Outline Style */
  &.is-style-outline .wp-block-button__link:not(.has-link-color) {
    --background: transparent;
    --color: var(--color-maroon-primary);
    --border-color: var(--color-maroon-primary);
    --hover-background: var(--color-maroon-primary);
    --hover-color: #fff;
  }

  /* Subtle Style */
  &.is-style-subtle .wp-block-button__link:not(.has-link-color) {
    --background: transparent;
    --color: var(--color-maroon-primary);
    --border-color: transparent;
    --hover-background: transparent;
    --hover-color: var(--color-maroon-dark);
  }
}

/* Maroon Section Style */
.is-style-section-maroon,
.is-style-section-navy {
  .wp-block-button {
    .wp-block-button__link {
      --background: #fff;
      --color: var(--color-maroon-primary);
      --border-color: #fff;
      --hover-background: var(--color-maroon-primary);
      --outline-color: #fff;
      --hover-border-color: #fff;
    }

    &.is-style-outline .wp-block-button__link {
      --color: rgba(255 255 255 / 90%);
      --border-color: rgba(255 255 255 / 70%);
      --hover-background: var(--color-maroon-primary);
      --hover-color: rgba(255 255 255 / 100%);
    }

    &.is-style-subtle .wp-block-button__link {
      --color: #fff;
      --hover-color: #fff;
      --hover-border-color: transparent;
    }
  }
}

/* Navy Section Style */
.is-style-section-navy .wp-block-button {
  .wp-block-button__link {
    --color: var(--color-navy-primary);
    --border-color: var(--color-navy-primary);
    --hover-background: var(--color-navy-primary);
    --hover-border-color: #fff;
  }

  &.is-style-outline .wp-block-button__link {
    --hover-background: var(--color-navy-primary);
  }
}

/* Light Blue Section Style */
.is-style-section-light-blue .wp-block-button {
  .wp-block-button__link {
    --color: #fff;
    --border-color: var(--color-navy-primary);
    --background: var(--color-navy-primary);
    --hover-background: var(--color-navy-lighter);
    --hover-border-color: var(--color-navy-lighter);
    --outline-color: var(--color-navy-primary);
  }

  &.is-style-outline .wp-block-button__link {
    --color: var(--color-navy-primary);
    --border-color: var(--color-navy-primary);
    --hover-background: var(--color-navy-lighter);
    --hover-color: rgba(255 255 255 / 100%);
  }

  &.is-style-subtle .wp-block-button__link {
    --color: var(--color-navy-primary);
    --hover-color: var(--color-navy-lighter);
  }
}
