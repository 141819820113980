.wp-block-group {
  &.alignwide {
    max-width: var(--wp--style--global--wide-size);
    width: 100%;
    margin-inline: auto;
  }

  /* &.is-content-justification-left {
    margin-inline: 0 auto;
  }

  &.is-content-justification-center {
    margin-inline: auto;
  }

  &.is-content-justification-right {
    margin-inline: auto 0;
  } */
}
