.rounded-clamped-lg {
  border-radius: theme(borderRadius.lg);
}

.rounded-clamped-t-lg {
  border-top-left-radius: theme(borderRadius.lg);
  border-top-right-radius: theme(borderRadius.lg);
}

.rounded-clamped-b-lg {
  border-bottom-left-radius: theme(borderRadius.lg);
  border-bottom-right-radius: theme(borderRadius.lg);
}

.rounded-clamped-xl {
  border-radius: theme(borderRadius.xl);
}

.rounded-clamped-t-xl {
  border-top-left-radius: theme(borderRadius.xl);
  border-top-right-radius: theme(borderRadius.xl);
}

.rounded-clamped-b-xl {
  border-bottom-left-radius: theme(borderRadius.xl);
  border-bottom-right-radius: theme(borderRadius.xl);
}

.overflow-hidden {
  overflow: hidden;
}
